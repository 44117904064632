(function (angular) {

    "use strict";

    angular.module("app")
        .run(function ($rootScope, $http, AuthSvc, UserSvc) {

            var auth_token = AuthSvc.getToken();
            var user = AuthSvc.getUser();

            //Check if the user already has a auth token present (they're logged in)
            if (auth_token != null) {


                setTimeout($rootScope.$emit.bind($rootScope, ["login"]), 200);

                //If they are, set the auth token (JWT) as a default header for all requests made by the app.
                $http.defaults.headers.common["x-auth"] = auth_token;                
            }

        });

}) (angular);