(function (angular) {
    "use strict";

    angular.module("app")
        .controller("OrderConfirmedCtrl", function ($scope, Order, OrderSvc) {

        	$scope.order = Order.data;

        });

})(angular);