(function (angular) {

	"use strict";
	angular.module("app")
		.service("BasketSvc", function ($rootScope, $http, Config, ProductSvc) {

            var storage = window.localStorage;
            
            //Look for a cached basket, otherwise set it to an empty object
            var basket = JSON.parse(storage.getItem("bello-basket")) || [];
            
            var self = this;

            /**
             * @function get Returns the basket
             */
            this.get = function () {
                return basket;
            };

            /**
             * @function add Adds an item to the basket
             * @param {Object} product Product to add to the basket
             */
            this.add = function (product, quantity) {

                if (quantity < 0) {
                    return;
                }
                
                //Check for existing, matching product in the basket.
                var exists = _.find(basket, function (p) { return p.product._id == product._id; });
                //Total units, including units in basket + units to add via quantity param
                var totalUnits = 0;
                //Stock for product
                var stock = product.stock;
                
                //Only add the product if it isn't present in the basket already
                if (!exists) {

                    if (quantity > stock) {
                        $rootScope.$emit("notify", {
                            message: "Can't add (0) / +" + quantity + " to basket, only " + stock + " units in stock."
                        });
                        return false;
                    }
                    basket.push({
                        product: product,
                        quantity: Math.abs(quantity)
                    });

                }
                else {

                    totalUnits = exists.quantity + quantity;

                    if (totalUnits > stock) {
                        
                        $rootScope.$emit("notify", {
                            message: "Can't add ("+exists.quantity+") / +" + quantity + " to basket, only " + stock + " units in stock."
                        });

                        return false;
                    }
                    //If product is already in the basket, you can minus it
                    exists.quantity += quantity;

                    //Sense-check, to make sure no minus figures get entered
                    if (exists.quantity < 0) {
                        exists.quantity = 0;
                    }
                }

                //Update the cached basket 
                storage.setItem("bello-basket", JSON.stringify(basket));

                return basket;
            };

            /**
             * @function update Accepts an updated version of the basket, and reassigns the cache and local copy with the new one.
             * @param {Object} basket Basket object, retrieved from this service.
             */
            this.update = function (basket) {
                
                //Update the cache
                storage.setItem("bello-basket", JSON.stringify(basket));
                
                //Update the local (servive) basket variable
                basket = basket;

                return this;
            };

            /**
             * @function getTotalValue Returns the current £total of the items in the basket.
             */
            this.getTotalValue = function () {

                var total = 0;
                _.forEach(basket, function (p) {
                    total += (p.product.price * p.quantity);
                });
                
                return total.toFixed(2);
            };

            /**
             * @function reset Resets the basket, clearing all order items
             */
            this.reset = function () {
                storage.setItem("bello-basket", JSON.stringify([]));
                basket = [];
            };

            /**
            * @function purchaseOrder Create an order for the current basket
            */
            this.purchaseOrder = function () {
                return $http.post(Config.API_BASE + "order", {
                    basket: basket
                });
            };


            /**
             * @function updateAPI Fetches the products from the API, updating the local copy of fields stored in the basket
             */
            this.updateAPI = function () {
     
                //Get an array of product IDS to check for updates on
                var product_ids = basket.map(function (item) {

                    if (item.product) {
                        return item.product._id;                
                    }
                });

                //Go fetch the products
                ProductSvc.getList(product_ids)
                    .then(function (res) {

                        //Now update the basket product details with the fresh product details fetched from the API
                        var products = res.data || [];
                        var p;

                        //Loop through the products
                        for (var i = 0, max = products.length; i < max; i++) {
                            
                            //Loop through the basket
                            for (var j = 0, jMax = basket.length; j < jMax; j++) {
                                
                                p = basket[j].product;

                                //Update the product object if an _id match is found.
                                if (p._id == products[i]._id) {
                                    //Update the basket's local copy
                                    basket[j].product = products[i];

                                    //Check if there is no stock left of this item, 
                                    //REMOVE IT FROM THE BASKET if so.
                                    if (basket[j].product.stock <= 0) {
                                        basket.splice(j, 1);
                                    }
                                    //Otherwise, update the stock accordingly
                                    else if (basket[j].quantity >= basket[j].product.stock) {
                                        basket[j].quantity = basket[j].product.stock;
                                        //Could add a message to the user here?
                                        // $rootScope.$emit("notify", { message: "We've had to alter the quantity of one of your order items, please check your basket."});
                                    }
                                }
                            }   
                        }

                        //Update the basket, persisting the changes.
                        self.update(basket);

                    }, function (err) {

                        console.warn("Error getting products from basket updateAPI");
                        console.log(err);
                    });
            };
		});

}) (angular);