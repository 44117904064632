(function (angular) {
	


	"use strict";
	angular.module("app")
		.service("UploadSvc", function ($http, Config, ProductSvc, $interval, $rootScope, Upload) {


            var mimeToExtension = {
                "image/png" : "png",
                "image/jpeg": "jpeg",
                "image/jpg": "jpg"
            };

            var self = this;
            
            //Converts a base64 data url into a file object
            //We'll use this for uploading any images to the API.
            function dataURLtoFile(dataurl, filename) {
                var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename + "." + mimeToExtension[mime] , {type:mime});
            }

            this.base64ToBlob = function (base64, cb) {
                
                if (!base64) {
                    return cb(null);
                }

                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                var img = new Image();

                img.onload = function () {

                    canvas.width = img.width;
                    canvas.height = img.height;

                    ctx.drawImage(img, 0, 0);

                    canvas.toBlob(function (blob) {

                        return cb(blob);
                    });
                };

                img.src = base64;
            }

            //Store a local reference to localStorage
            var storage = window.localStorage;

            //Get / Create the queue of products to upload
            var product_queue = JSON.parse(storage.getItem("bello-product-queue")) || [];

            //Track if a product is uploading, preventing multiple upload attempts.
            var product_uploading = false;

            //Get / Create the queue of products to upload
            var edits_queue = JSON.parse(storage.getItem("bello-edits-queue")) || [];
            
            //Track if a product is uploading, preventing multiple upload attempts.
            var edits_uploading = false;

            //How long before each check for upload (milliseconds)
            var upload_interval = 10000;

            //Check for the que having items, and upload

            $interval(function () {

                (function () {

                    //Return if we're already uploading OR there are no products.
                    if (edits_uploading == true || edits_queue.length <= 0) {
                        return;
                    }

                    edits_uploading = true;

                    uploadEdits();

                })();

                (function () {
                    
                    //Return if we're already uploading OR there are no products.
                    if (product_uploading == true || product_queue.length <= 0) {
                        return;
                    }

                    product_uploading = true;

                    uploadProduct();

                })();
                

            }, upload_interval);





            var uploadProduct = function () {
                

                self.base64ToBlob(product_queue[0]["image"], function (blob) {
                    
                    product_queue[0]["file"] = blob;

                    ProductSvc.create(product_queue[0])
                        .then(function (res) {

                            $rootScope.$emit("notify", {message:"Success creating products."});
                            console.log(res);

                            //Remove the item from the queue
                            product_queue.splice(0, 1);

                            //Save the new version into storage
                            storage.setItem("bello-product-queue", JSON.stringify(product_queue));

                            product_uploading = false;

                        }, function (err) {

                            console.log("Error uploading image.");
                            console.log(err);                        
                            //Add the error object onto te model for reference in the app
                            //I.e. showing the user which ones failed.
                            product_queue[0].err = err.data;
                            product_queue[0].err_attempt = new Date();

                            //Move the error product to the last position.
                            //This will prevent the error product stopping products which are OK
                            //uploading.
                            if (product_queue.length > 1) {
                                product_queue.move(0, product_queue.length - 1);
                            }

                            //Update storage copy
                            storage.setItem("bello-product-queue", JSON.stringify(product_queue));
            
                            product_uploading = false;
                            
                            $rootScope.$emit("notify", {message:"Error creating products"});
                        });
                });

            };

            var uploadEdits = function () {

                self.base64ToBlob(edits_queue[0].image, function (blob) {
                    
                    if (blob) {
                        edits_queue[0]["file"] = blob;
                    }

                    ProductSvc.update(edits_queue[0])
                        .then(function (res) {

                            $rootScope.$emit("notify", {message:"Success uploading edits."});

                            //Remove the item from the queue
                            edits_queue.splice(0, 1);
                            
                            //Save the new version into storage
                            storage.setItem("bello-edits-queue", JSON.stringify(edits_queue));

                            edits_uploading = false;

                        }, function (err) {
                            console.log("Error uploading edit.");
                            
                    
                            //Add the error object onto te model for reference in the app
                            //I.e. showing the user which ones failed.
                            edits_queue[0].err = err.data;
                            edits_queue[0].err_attempt = new Date();

                            //Move the error product to the last position.
                            //This will prevent the error product stopping products which are OK
                            //uploading.
                            if (edits_queue.length > 1) {
                                edits_queue.move(0, edits_queue.length - 1);
                            }

                            //Update storage copy
                            storage.setItem("bello-edits-queue", JSON.stringify(edits_queue));
            
                            edits_uploading = false;
                            
                            $rootScope.$emit("notify", {message:"Error creating product edits."});
                        });
                });

            };


            this.getQueue = function() {
                return product_queue;
            }


            //Adds a product to the queue, ready for upload.
            this.addProduct = function (product) {

                product_queue.push(product);
                storage.setItem("bello-product-queue", JSON.stringify(product_queue));

                return true;
            };
  

            //Adds a product to the queue, ready for upload.
            this.addEdit = function (product) {

                //Check for duplicates
                var index = -1;
                for (var i = 0, max = edits_queue.length; i < max; i++) {
                    if (edits_queue[i]._id == product._id) {
                        index = i;
                        break;
                    }
                }

                if (index === -1) {
                    edits_queue.push(product);                
                }
                else {
                    //Overwrite the duplicate if found.
                    edits_queue[index] = product;
                }
                // console.log(edits_queue);
                // console.log("edits_queue after add above");
                storage.setItem("bello-edits-queue", JSON.stringify(edits_queue));

                return true;
            };

            this.deleteNewUpload = function (upload) {

                _.remove(product_queue, upload);

                storage.setItem("bello-product-queue", JSON.stringify(product_queue));
            };
  
        });

}) (angular);