(function (angular) {
	
	"use strict";
	angular.module("app")
		.service("OrderSvc", function ($http, Config) {

			/**
			* @function getList Returns a list of all products
			*/
			this.getList = function () {
				return $http.get(Config.API_BASE + "product");
			};

			/**
			* @function my Returns a list of my orders.
			*/
			this.my = function () {
				return $http.get(Config.API_BASE + "order/my");
			};

			this.get = function (order_id) {
				return $http.get(Config.API_BASE + "order/" + order_id);
			};

			/**
			 * @function getOrderThread Fetches the populated list of messages in the order's 'thread' property
			 */
			this.getOrderThread = function (order_id) {
				return $http.get(Config.API_BASE + "order/thread/" + order_id);
			};

			/**
			 * @function update Updates a given order
			 */
			this.update = function (order) {
				return $http.put(Config.API_BASE + "order/" + order._id, order);
			};

			/**
			 * @function newThreadMessage Adds a new message into the order's thread.
			 */
			this.newThreadMessage = function (message, order_id) {
				return $http.post(Config.API_BASE + "order/thread/" + order_id, message);
			};

		});

}) (angular);