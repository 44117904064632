(function (angular) {
    "use strict";

    angular.module("app")
        .controller("MyOrdersCtrl", function ($scope, OrderSvc) {



        	$scope.orders = [];

        	var getOrders = function () {

        		OrderSvc.my()
        			.then(function (res) {
        				console.log("Success getting my orders.");
        				$scope.orders = res.data;
        				console.log($scope.orders);
        			}, function (err) {
        				console.log("Error getting orders.");
        				console.log(err);
        			});
        	};

        	getOrders();
        });

})(angular);