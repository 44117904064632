(function (angular) {
    "use strict";

    angular.module("app")
        .directive("repeatDone", function ($timeout) {
        
            return function($scope, $element, $attributes) {
                
                if ($scope.$last === true) {
                    $timeout(function () {
                        console.log("hit")
                        $scope.$emit($attributes.repeatDone);
                    });
                }
                $element.bind("$destroy", function (event) {
                    
                    if ($scope.$last) {
                        $scope.$eval($attributes.repeatDone);
                    }
                });
            };
        });

}) (angular);