(function (angular) {

    "use strict";

    angular.module("app")
        .directive("notificationList", function ($state, $cordovaBadge, $rootScope, NotificationFactory, PaginationFactory, NotificationSvc) {

            var DEFAULT_ITEMS_PER_PAGE = 20;

            return {
                templateUrl: "views/directives/notification-list.html",
                restrict: "E",
                scope: {
                    itemsPerPage: "="
                },
                link: function ($scope, $element, $attributes) {
                        

                    $scope.pagination = new PaginationFactory({
                        itemsPerPage: $scope.itemsPerPage || DEFAULT_ITEMS_PER_PAGE
                    });

                    $scope.loadNotification  = function  (notification) {


                        //Register the notification as having been seen.
                        NotificationSvc
                            .seen(notification._id)
                            .then(function (res) {
                                document.addEventListener('deviceready', function () {
                                    $cordovaBadge.decrease(1);
                                }, false);
                            }, function (err) {});
                        
          
                        setTimeout(function () {

                            if (notification.type === "product_post") {
                                $state.go("product", {id: notification.product._id})
                            }
                            else {
                                $state.go("manage-order", {id: notification.order});
                            }

                        }, 100);

                        return false;
                        
                    };

                    $scope.getNotifications = function () {

                        //Check if there are any more entries to load
						if ($scope.pagination.currentItems >= $scope.pagination.totalItems) {
							return;
						}
                        //Update the pagination object
						$scope.pagination.paginate();

                        NotificationSvc.getList($scope.pagination.start, $scope.pagination.itemsPerPage)
                            .then(function (res) {

                                $rootScope.$emit("notification_get", { not_seen_count: res.data.not_seen_count });

                                $scope.notifications = res.data.data.map(function (p) {
                                    return new NotificationFactory(p);
                                });

                                //Update the pagination data 
								$scope.pagination.setTotalItems(res.data.count);

                            }, function (err) {
                                console.log("Error getting notifications");
                                console.log(err);
                            });
                    };

                    $scope.getNotifications();
                }
            };
        });

}) (angular);