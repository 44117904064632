(function () {
    "use strict";

    angular.module("app")
        .factory("MessageFactory", function () {

            var Message = function (props) {
                var p = props || {};
                this.content = p.content || "";
                (p._id != undefined) ? (this._id = p._id) : null;
                (p.createdon != undefined) ? (this.createdon = new Date(p._id)) : null;
            }

            return Message;
        });
})();