(function (angular) {

    "use strict";

    angular.module("app")
        .directive("order", function (OrderSvc, Config) {

            return {
                templateUrl: "views/directives/order.html",
                restrict: "E",
                scope: {
                    order: "=",
                    showProductImage: "="
                },
                link: function ($scope, $element, $attributes) {

                    $scope.MEDIA_BASE = Config.API_BASE;

                    $scope.cancelOrder = function () {

                        var old_status = $scope.order.status;
                        $scope.order.status = "cancelled";
                        OrderSvc.update($scope.order)
                            .then(function (res) {

                                $scope.$emit("notify", {message: "Order updated."});
                                
                            }, function (err) {

                                //It failed, reset the view back to it's original state
                                $scope.order.status = old_status;
                                $scope.$emit("notify", {message: "An error occured updating your order."});
                            });
                    };
                }
            };
        });

}) (angular);