(function (angular) {
    "use strict";

    angular.module("app")
        .controller("LoginCtrl", function ($scope, AuthSvc, $state) {
            
            $scope.credentials = {
                email: "",
                password: ""
            };

            //Error message, displayed on the login form.
            $scope.error = undefined;

            $scope.login = function () {

                $scope.error = undefined;

                AuthSvc.login($scope.credentials)
                    .then(function (res) {
                        
                        AuthSvc.authenticate(res.data.jwt, res.data.user);
                        
                        $state.go("home");
                    },
                    function (err) {
                        console.log("Error logging in.");
                        console.log(err);

                        if (err.data && err.data.message) {
                            $scope.error = err.data.message;
                        }
                    });
            };
        });

})(angular);