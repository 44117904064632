(function (angular) {
    "use strict";

    angular.module("app")
        .controller("EditProductCtrl", function ($scope, $state, Product, ProductFactory, UploadSvc, Config, $cordovaCamera) {
 
            $scope.editing = true;  
            $scope.MEDIA_BASE = Config.API_BASE;

            //Product model
            $scope.product = new ProductFactory(Product.data);
            
            //Track errors for display in the view
            $scope.errors = {};

            //Track if we're already saving, i.e. stop double saves.
            var saving = false; 
            
            $scope.save = function () {

                if (saving === true) {
                    return;
                }

                saving = true;

                //Reset the view errors
                $scope.errors = {};

                //We need to validate here as we're just passing the object to the upload service
                //there won't be any API validation to tell us if the data is bad.
                var validated = false;

                //Validate the view data
                validated = $scope.product.validate();
                
                if (validated.err === true) {

                    //Populate the errors on scope for display
                    console.log(validated.data);
                    $scope.errors = validated.data;
                    $scope.$emit("notify", {message: "There was an error validating , please check the form for feedback."});
                    saving = false;
                    return;
                }

                //Add product to the upload service.
                UploadSvc.addEdit($scope.product);

                //Free up for the next attempt.
                saving = false;

                $state.go("home");
            };

            $scope.clear = function () {
	            $scope.product = new ProductFactory();
            };

             /**
             * @param {String} source_type Where to source the image from ['camera', 'photos'] 
             */
            $scope.addPicture = function (source_type) {

                $scope.loadingCamera = true;

                var source = (source_type.toLocaleLowerCase() === "photos") 
                    ? Camera.PictureSourceType.PHOTOLIBRARY 
                    : Camera.PictureSourceType.CAMERA;
                                
                document.addEventListener("deviceready", function () {
                    var options = {
                        destinationType: Camera.DestinationType.DATA_URL,
                        encodingType: Camera.EncodingType.JPEG,
                        quality: 70,                        
                        targetWidth: 1000,
                        targetHeight: 1000,
                        sourceType: source,
                        saveToPhotoAlbum: true,
                        correctOrientation: true,
                  
                    };

                    $cordovaCamera.getPicture(options).then(function(imageURI) {
                        //Hide the loader...
                        $scope.loadingCamera = false;

                        $scope.product.image = "data:image/jpeg;base64," + imageURI;
                        console.log("$scope.product")
                        console.log($scope.product)
                }, function(err) {
                        // error
                        console.log("Error capturing picture");
                        console.log(err);
                        //Hide the loader...
                        $scope.loadingCamera = false;                        
                    });

                });
            };

        });

})(angular);