(function (angular) {
    "use strict";

    angular.module("app")
        .controller("HomeCtrl", function ($scope, $anchorScroll, ProductSvc, BasketSvc, $timeout, $stateParams, $state) {

            $scope.ui = {
                //Display the products tab first if the listings state param has been passed
                featured: (!$stateParams.listings ? true : false ),
                showLoader: ($stateParams.product_key) ? true : false
            };

            $scope.productKey = ($stateParams.hasOwnProperty("product_key") === true) ? $stateParams.product_key : null;

            //List of all products in the catalogue
        	$scope.products = [];

            //Filter the featured products for the what's hot view
            $scope.featured = []        	

            //Store a boolean to track if we've executed a routing change onload yet
            //e.g. scrolling to a product category when first landing on the page
            $scope.onLoadRoutingCheck = false;
            
            var getProducts = function () {
                ProductSvc.getList()
                    .then(function (res) {

                        $scope.products = res.data.data;

                    }, function (err) {
                        
                        console.warn("Error getting products.");
                        console.log(err);
                    });
            };
            
            var getWhatsHot = function () {
                ProductSvc.getWhatsHot()
                    .then(function (res) {
                        // console.log("Success getting what's hot.");
                        // console.log(res);
                        $scope.featured = res.data.data;
                    }, function (err) {
                        console.log("Error getting what's hot.");
                        console.log(err);
                    });
            };

            $scope.toggleWhatsHot = function () {

                $timeout(function () {
                    
                    $(window).trigger("resize");
                });
            };

            $scope.$on("productsRendered", function (ngRepeatFinishedEvent) {
                

                $timeout(function () {

                    //Check if a product_key was passed to state, if so 
                    if ($scope.onLoadRoutingCheck === false && $stateParams.product_key) {  
                        
                        $scope.onLoadRoutingCheck = true;
                        $anchorScroll.yOffset = 50  ;
                        $anchorScroll($stateParams.product_key);
    
                        $timeout(function () {
    
                            $scope.ui.showLoader = false;
                        }, 630);
                    }
                });
            });
            
            getWhatsHot();
            getProducts();
    
    });

})(angular);