(function (angular) {
	
	"use strict";

	angular.module("app")
		.service("AuthSvc", function ($http, Config, $state, $rootScope) {

            //Store a local reference to localStorage
            var storage = window.localStorage;
            
            //Store the current logged in user.
            this.user = JSON.parse(storage.getItem("current-user")) || undefined;

            this.userGroups = JSON.parse(storage.getItem("bello-user-groups")) || undefined;

            this.login = function (credentials) {
                return $http.post(Config.API_BASE + "public/login", credentials);
            };


            this.authenticate = function (token, user) {

                $rootScope.$emit("login");
                $http.defaults.headers.common["x-auth"] = token;                
                storage.setItem("bello-api-key", JSON.stringify(token));
                this.cacheUser(user);
            };

            this.cacheUser = function (user) {
                storage.setItem("current-user", JSON.stringify(user));
                this.user = user;
                
                var self = this;
                this.getUserGroups().then(function (res) {

                    storage.setItem("bello-user-groups", JSON.stringify(res.data));
                    self.userGroups = res.data;
                }, function (err) {
                    console.log("Error fetching user groups.");
                    console.log(err);
                });
            };

            /**
             * @function clearCache clear's the cache, flushing out the user from local scope & persistent storage
             */
            this.clearCache = function () {
                storage.removeItem("current-user");
                this.user = undefined;
            };

            this.getToken = function () {
                return JSON.parse(storage.getItem("bello-api-key"));
            };

            this.getUser = function () {
                //Clone deep to stop a reference being passed to the private scoped user
                return _.cloneDeep(this.user);
            };

            this.getUserGroups = function () {
                return $http.get(Config.API_BASE + "usergroups");
            };

            /**
            * @function logout Logs the user out of the app, removing their API key.
            *  They will need network access to log back into the App..
            */
            this.logout = function (key) {
                storage.removeItem("bello-api-key");
                this.clearCache();
                $state.go("login");
            };
  
        });

}) (angular);