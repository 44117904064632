(function (angular) {
    "use strict";

    angular.module("app")
        .controller("CreateProductCtrl", function ($scope, ProductFactory, UploadSvc, $cordovaCamera, Upload) {
                
            //Product model
            $scope.product = new ProductFactory();
            
            //Track errors for display in the view
            $scope.errors = {};

            $scope.editing = false;

            //Track if we're already saving, i.e. stop double saves.
            var saving = false;

            //USed to display a loader over the screen as the camera/album plugin is initialising
            $scope.loadingCamera = false;

            $scope.save = function () {

                if (saving === true) {
                    return;
                }

                saving = true;

                //Reset the view errors
                $scope.errors = {};

                //We need to validate here as we're just passing the object to the upload service
                //there won't be any API validation to tell us if the data is bad.
                var validated = false;

                //Validate the view data
                validated = $scope.product.validate();
                
                if (validated.err === true) {

                    //Populate the errors on scope for display
                    $scope.errors = validated.data;
                    $scope.$emit("notify", {
                        message: "There was an error validating , please check the form for feedback.",
                        type: "error"
                    });
                    saving = false;
                    return;
                }

                //Add product to the upload service.
                UploadSvc.addProduct($scope.product);

                //Refresh the model.
                $scope.product = new ProductFactory();

                //TODO Add scroll to top

                //Free up for the next attempt.
                saving = false;
                
                $scope.$emit("notify", {
                    message: "Product added to upload queue"
                });
               
            };

            $scope.clear = function () {
	            $scope.product = new ProductFactory();
            };

            $scope.webFileChange = function ($files, $file, $newFiles, $duplicateFiles, $invalidFiles, $event) {

                /* Convert the file to blob url object or base64 data url based on boolean disallowObjectUrl value */
                Upload.dataUrl($file, true).then(function(url){

                    $scope.product.image = url;
                });
            };

            /**
             * @param {String} source_type Where to source the image from ['camera', 'photos'] 
             */
            $scope.addPicture = function (source_type) {

                $scope.loadingCamera = true;

                document.addEventListener("deviceready", function () {

                    var source = (source_type.toLocaleLowerCase() === "photos") 
                        ? Camera.PictureSourceType.PHOTOLIBRARY 
                        : Camera.PictureSourceType.CAMERA;
                                    
                    var options = {
                        destinationType: Camera.DestinationType.DATA_URL,
                        encodingType: Camera.EncodingType.JPEG,
                        quality: 70,                        
                        targetWidth: 650,
                        targetHeight: 650,
                        sourceType: source,
                        saveToPhotoAlbum: true,
                        correctOrientation: true
                    };

                    $cordovaCamera.getPicture(options).then(function(imageURI) {
                        //Hide the loader...
                        $scope.loadingCamera = false;

                        $scope.product.image = "data:image/jpeg;base64," + imageURI;
                    }, function(err) {
                        // error
                        console.log("Error capturing picture");
                        console.log(err);
                        //Hide the loader...
                        $scope.loadingCamera = false;                        
                    });

                });
            };

        });

})(angular);