(function (angular) {

    "use strict";

    angular.module("app")
        .run(function (BasketSvc, AuthSvc) {

            //How long to wait before performing the first update of the basket
            var WAIT_TIME = 1000;

            //How long in-between communications with the API to ensure the basket values are correct
            var BACKGROUND_INTERVAL = 240000; //4 Minutes
            
            //Update the basket on loading of the app.
            setTimeout(function () {

                //Check if the user is authorized
                if (AuthSvc.getUser() == null) {
                    return;
                }

                BasketSvc.updateAPI();

                //Trigger the interval which keeps the basket updated.
                setInterval(function () {
                    BasketSvc.updateAPI();
                }, BACKGROUND_INTERVAL)
            }, WAIT_TIME);
        });

}) (angular);