(function (angular) {
    "use strict";

    angular.module("app")
        .controller("PublicShopCtrl", function ($scope, $anchorScroll, ProductSvc, BasketSvc, $timeout, $stateParams, $state) {


            //List of all products in the public shop
        	$scope.products = [];   	

            
            var getProducts = function () {
                ProductSvc.getPublicList()
                    .then(function (res) {
                        $timeout(function () {

                            $scope.products = res.data.data;
                        });
                    }, function (err) {
                        
                        console.warn("Error getting products.");
                        console.log(err);
                    });
            };

                       
            getProducts();
    
    });

})(angular);