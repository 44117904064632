(function (angular) {
    "use strict";

    angular.module("app")
        .directive("whatsHot", function ($timeout, Config) {

            return {

                templateUrl: "views/directives/whats-hot-slide.html",
                scope: {
                    listings: "="
                },
                link: function ($scope, $element, $attributes) {

                    $scope.MEDIA_BASE = Config.API_BASE;


                    $(window).trigger('resize');

                    // $scope.$on("whatsHotRendered", function () {
                    //     alert(1);
                    // });

                    // $scope.slick = function () {
                    //     console.log("Slicking...");
                    //     $timeout(function () {

                    //         $("#whats-hot").slick({
                    //             lazyLoad: "ondemand",
                    //             slidesToShow: 1,
                    //             slidesToScroll: 1,
                    //             fade: true,
                    //             cssEase: "linear",
                    //             infinite: true,
                    //             speed: 500,
                    //             dots: true
                    //         });
                    //     }, 1);
                    // };
                    
                }
            };
        });
})(angular);