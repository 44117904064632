(function (angular) {
    "use strict";

    angular.module("app")
        .controller("ManageOrdersCtrl", function ($scope, AdminSvc, PaginationFactory) {

            var ITEMS_PER_PAGE = 20;

            $scope.orders = [];
            $scope.ui = {
                status: false,
                start_date: moment().subtract(1,"days").startOf("day").toDate(),
                end_date: moment().endOf("day").toDate(),
            };

            function getSearchParams() {
                var params = {
                    start: $scope.pagination.start || 0,
                    limit: $scope.pagination.itemsPerPage,
                }

                if ($scope.ui.status) {
                    params["status"] = $scope.ui.status;
                }

                if ($scope.ui.start_date && $scope.ui.end_date) {
                    params["start_date"] = $scope.ui.start_date;
                    params["end_date"] = $scope.ui.end_date;
                }

                return params;
            }

            $scope.getExportDownload = function () {
                var order_params = getSearchParams();
                AdminSvc.downloadOrderExport(order_params);
            }

            /**
             * @description For paginating the orders table
             */
            $scope.pagination = new PaginationFactory({
                itemsPerPage: ITEMS_PER_PAGE
            });

            $scope.getOrders = function () {

                if ($scope.pagination.currentItems >= $scope.pagination.totalItems) {
                    return;
                }

                //Update the pagination object
                $scope.pagination.paginate();

                var order_params = getSearchParams();

                AdminSvc.orderGetList(order_params).then(function (res) {
                    $scope.orders = res.data.data;

                    // Update the pagination data 
                    $scope.pagination.setTotalItems(res.data.count);
                }, function (err) {
                    console.log("Error getting orders.");
                    console.log(err);
                    $scope.$emit("notify", {message: "An error occurred searching the orders."})
                });
            };

            $scope.getOrders();
        });

})(angular);