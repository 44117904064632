(function (angular) {

    "use strict";

    angular.module("app")
        .directive("notificationLabel", function ($state, $rootScope) {

            return {
                templateUrl: "views/directives/notification-label.html",
                restrict: "E",
                scope: {
                    text: "=",
                    badge: "="
                },
                link: function ($scope, $element, $attributes) {
                    
                    $scope.notSeenCount = $rootScope.notification_count || 0;
                    $rootScope.$on("notification_get", function (_, data) {

                        $scope.notSeenCount = parseInt(data.not_seen_count, 10);
                    });
                }
            };
        });

}) (angular);