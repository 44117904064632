(function (angular) {
	
	"use strict";
	angular.module("app")
		.service("NotificationSvc", function ($http, Config) {

			/**
			* @function getList Returns a list of notifications 
			* @param {Number} skip Pagination prop
			* @param {Number} limit Pagination prop
			*/
			this.getList = function (skip, limit) {
				return $http.get(Config.API_BASE + "notification/getlist?&skip=" + skip + "&limit=" + limit);
			};

			/**
			 * @function seen Register a notification as having been seen by the logged in user.
			 * @param {String} notification_id _ID field of the notication object being seen
			 */
			this.seen = function (notification_id) {
				return $http.put(Config.API_BASE + "notification/seen?notification_id=" + notification_id);
			};
			
			this.registerUserId = function (id) {
				return $http.put(Config.API_BASE + "notification/registerpushid?push_id=" + id);				
			};
		});

}) (angular);