(function (angular) {
	
	"use strict";

	angular.module("app")
		.factory("ProductFactory", function () {


			var Product = function (props) {
				
				//Assign either the given, or default fields.
				props ? this.applyProvidedFields(props) : this.applyDefaultFields();
			}; 

			/**
			* @function validate Validate the model before it gets passed for upload
			*/
			Product.prototype.validate = function (product) {
				
				var result = {
					err: false,
					data: {}
				};
				var errors = [];
				if (this.title.length <= 0) {
					result.err = true;
					result.data.title = "Title is required.";
				}
				if (this.description.length <= 0) {
					result.err = true;
					result.data.description = "Description is required.";
				}
				if (this.price === undefined || this.price === null) {
					result.err = true;
					result.data.price = "Price must be defined.";
				}
				if (!this.category || this.category.length <= 0) {
					result.err = true;
					result.data.category = "Category must be defined.";
				}
				if (this.unit.length <= 0) {
					result.err = true;
					result.data.unit = "Unit must be defined.";
				}

				return result;
			};

			Product.prototype.applyProvidedFields = function (props) {
				var p = props || {};

				for (var i in p) {

					if (p.hasOwnProperty(i) === true) {
						this[i] = p[i];
					}
				}
			};

			Product.prototype.applyDefaultFields = function () {
				this.title = "";
				this.latin_title = "";
				this.description = "";
				this.sourced = {
					from: "",
					date: new Date(),
					timeofday: "unknown"
				};
				this.price = 0.00;
				this.unit = "50g";
				this.featured = false;
				this.stock = 0;
				this.category = [];
				this.active = true;
				this.foragers_find = false;
				this.public_facing = false;
				this.real = true;
			};

			return Product;
		});


}) (angular);