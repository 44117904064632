(function (angular) {
    
        "use strict";
    
        angular.module("app")
            .directive("productCard", function (BasketSvc, Config) {
    
                return {
                    templateUrl: "views/directives/product-card-sm.html",
                    restrict: "E",
                    scope: {
                        product: "=",
                        public: "="
                    },
                    link: function ($scope, $element, $attributes) {

                        console.log("PUBLIC = ");
                        console.log($scope.public);
                        
                        $scope.MEDIA_BASE = Config.API_BASE;
                        
                        $scope.addToBasket = function (product, quantity) {
                            BasketSvc.add(product, quantity);
            
                            $scope.$emit("notify", { message: "Product added to basket."});
                        };

                    }
                };
            });
    
    }) (angular);