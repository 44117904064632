(function (angular) {
    "use strict";

    angular.module("app")
        .controller("ManageOrderCtrl", function ($scope, Order) {

        	$scope.order = Order.data;

        	console.log("$scope.order");
        	console.log($scope.order);

        	$scope.calculateSubTotal = function (product) {
        		console.log("Product = ");
        		console.log(product);
        	};
        });

})(angular);