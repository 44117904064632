(function (angular) {
	
	"use strict";

	angular.module("app")
		.controller("RegisterCtrl", function ($scope, UserSvc) {

			$scope.User = {};

			$scope.submit = function () {
				UserSvc.registerInterest($scope.User)
					.then(function (res) {

						$scope.$emit("notify", {
							message: "Thanks for registering an interest with our app.",
							type: "success"
						});
						$scope.User = {};
					}, function (err) {

						var err_res = err.data || {}; 

						$scope.$emit("notify", {
							message: "Error registering an interest: " + err_res.message,
							type: "error"
						});

					});
			};

		});

})(angular);