(function (angular) {
    "use strict";

    angular.module("app")
        .controller("CalendarAdminCtrl", function ($scope, CalendarSvc, $cordovaCamera, Upload) {

            $scope.file = null;
            $scope.newCalendar = {
                title: "",
                description: ""
            };

            $scope.loadingCamera = false;

            $scope.submit = function () {
                
                if (!$scope.file) {
                    $scope.$emit("notify", {message: "Please select a file."});                    
                    return;
                }
                
                CalendarSvc.create($scope.file, $scope.newCalendar)
                    .then(function (res) {

                        $scope.newCalendar = {
                            title: "",
                            description: ""
                        };

                        $scope.$emit("notify", {message: "Success uploading new calendar."});

                    }, function (err) {
                        $scope.$emit("notify", {message: "Oops! Error uploading new calendar."});                        
                    });
            };

            $scope.webFileChange = function ($files, $file, $newFiles, $duplicateFiles, $invalidFiles, $event) {

                /* Convert the file to blob url object or base64 data url based on boolean disallowObjectUrl value */
                Upload.dataUrl($file, true).then(function(url){

                    $scope.file = url;
                });
            };

            /**
             * @param {String} source_type Where to source the image from ['camera', 'photos'] 
             */
            $scope.addPicture = function (source_type) {

                if ($scope.loadingCamera === true) {
                    return;
                }
                
                $scope.loadingCamera = true;

                document.addEventListener("deviceready", function () {

                    var source = (source_type.toLocaleLowerCase() === "photos") 
                        ? Camera.PictureSourceType.PHOTOLIBRARY 
                        : Camera.PictureSourceType.CAMERA;
                                    
                    var options = {
                        destinationType: Camera.DestinationType.DATA_URL,
                        encodingType: Camera.EncodingType.JPEG,
                        quality: 70,                        
                        targetWidth: 650,
                        targetHeight: 650,
                        sourceType: source,
                        saveToPhotoAlbum: true,
                        correctOrientation: true
                    };

                    $cordovaCamera.getPicture(options).then(function(imageURI) {
                        //Hide the loader...
                        $scope.loadingCamera = false;

                        $scope.file = "data:image/jpeg;base64," + imageURI;
                    }, function(err) {
                        // error
                        console.log("Error capturing picture");
                        console.log(err);
                        //Hide the loader...
                        $scope.loadingCamera = false;                        
                    });

                });
            };

        });

})(angular);