(function (angular) {

    "use strict";

    angular.module("app")
        .run(function ($rootScope, NotificationSvc, AuthSvc, $cordovaBadge) {



            $rootScope.$on("login", function (_, data) {
                
                
                setTimeout(function () {

                    getNotifications();                
                }, 500);
            });
 

            //Update the App icon's badge with the number of un-read notifications.            
            $rootScope.$on("notification_get", function (_, data) {

                $rootScope.notification_count = data.not_seen_count || 0;

                document.addEventListener('deviceready', function () {

                    $cordovaBadge.set((data.not_seen_count || 0))

                }, false);
            });

            var getNotifications = function () {

                if (AuthSvc.getUser() == null) {
                    return;
                }
                
                NotificationSvc.getList(0, 1)
                    .then(function (res) {

                        $rootScope.$emit("notification_get", { not_seen_count: res.data.not_seen_count });
                    }, function (err) {
                        console.error("Erorr getting notifications on startup");
                        console.log(err);
                    });
            };
            
            getNotifications();
        }); 


})(angular);