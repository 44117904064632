(function (angular) {
    "use strict";

    angular.module("app")
        .directive("ngScrollBottom", function ($timeout, $location, $anchorScroll) {
            return {
                scope: {
                    ngScrollBottom: "="
                },
                link: function ($scope, $element) {

                    $scope.$watchCollection("ngScrollBottom", function (newValue) {
                        if (newValue) {
                            $timeout(function(){

                                 // set the location.hash to the id of
                                // the element you wish to scroll to.
                                $location.hash('bottom');

                                // call $anchorScroll()
                                $anchorScroll();
                            }, 0);
                        }
                    });
                }
            }
        });
        
})(angular);