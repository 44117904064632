(function (angular) {
	
	"use strict";
 
	angular.module("app")
		.directive("userTable", function (UserSvc, $rootScope, ConfirmModal, PaginationFactory) {
            
			return {
				templateUrl: "views/directives/user-table.html",
				scope: {

					//We will push/splice user's into this array
					exportUsers: "=",
					//What field of the user do we want to push onto the array, default is this (the object)
					exportField: "@",
					//Enable create button
					create: "=",
					//Enable edit button
					edit: "=",
					//Enable delete button
					delete: "=",
					//Enable search form
					search: "=",
					//Number of users per page
					itemsPerPage: "="
				},
				link: function ($scope, $element, $attr) {
					
					/**
					 * @description User's displayed in the view
					 */
					$scope.users = []

					/*
					* @description Variables specifically for the UI layer
					*/
					$scope.ui = {

						//This toggles the loading graphic on the search form.
						is_searching: false
					};

					$scope.confirm = new ConfirmModal();

					$scope.user_groups = $rootScope.user_groups;
					$scope.user = $rootScope.current_user;

					/**
					 * @description Search params for the user getList API call
					 */
					$scope.search_params = {
						search_text: "",
						user_group: false,
					};


					/**
					* @description What options will appear on the dropdown
					*/
					$scope.items_per_page_values = [10, 25, 50, 75];

					if ($scope.itemsPerPage && $scope.items_per_page_values.indexOf($scope.itemsPerPage) === -1) {
						$scope.items_per_page_values.push($scope.itemsPerPage).sort();
					}

					/**
					 * @description For paginating the users table
					 */
					$scope.pagination = new PaginationFactory({
						itemsPerPage: $scope.itemsPerPage || 12
					});

					/**
					 * @function toggleUser Pushes/Splices users onto the given exportUsers binding
					 */
					$scope.toggleUser = function (user) {

						var users = $scope.exportUsers;
						var index = -1;
						var field = $scope.exportField;
						var val = user;

						//Return if we have not provided a model to bind to.
						if (!users) {
							return;
						}

						if (field != null && user.hasOwnProperty(field)) {
							val = user[field];
						}

						index = users.indexOf(val)
						
						if (index === -1) {
							users.push(val) 
						}
						else {
							users.splice(index, 1);
						}

						return index;
					};

					/**
					 * @function fetchUsers Goes and fetches the users from the API.
					 */
					$scope.fetchUsers = function () {

						//Check if there are any more entries to load
						if ($scope.pagination.currentItems >= $scope.pagination.totalItems) {
							return;
						}
						
						//Update the pagination object
						$scope.pagination.paginate();

						//Show the loading graphic.
						$scope.ui.is_searching = true;
						
						UserSvc.getList($scope.pagination.start, $scope.pagination.itemsPerPage, $scope.search_params)
							.then(function (res) {

								$scope.users = res.data.data;

								//Update the pagination data 
								$scope.pagination.setTotalItems(res.data.count);

								$scope.ui.is_searching = false;

							}, function (err) {

								$scope.ui.is_searching = false;
								
							});
					};

					/**
					 * @function deleteUser Sends a DELETE request off to the API to delete a user account.
					 * @param {Object} user Instance of a user
					 */
					$scope.deleteUser = function (user) {

						//Check the user isn't trying to delete themselves.
						if (user._id === $scope.user._id) {
							$rootScope.$emit("notify", {message: "You cannot delete yourself."});
							return;
						}

						$scope.confirm.open({
							options: {
								text: "Are you sure you wish to delete this user? This cannot be undone.",
							},
							confirm: function () {

								//We're now clear to send off the delete user request.
								UserSvc.delete(user._id)
									.then(function (res) {

										$scope.fetchUsers();

										$rootScope.$emit("notify", {message: "User successfully deleted."});
										
									}, function (err) {

										$rootScope.$emit("notify", {message: "An error occurred deleting the user, please contact an administrator."});
									}); 
							},
							decline: function () {
								console.log("Action cancelled.");
							}
						})
					};

					
					(function () {

						$scope.fetchUsers();

					})();	

				}
			};
		});

})(angular);