(function(angular) {
	"use strict";

	var app = angular.module("app", [
		"ui.router",
		"ionic.native",
		"infomofo.angularMdPullToRefresh",
		"slickCarousel",
		"ngFileUpload",
		"ui.bootstrap",
		"ngNotify",
		"ngFileUpload"
	]);

	//Work out dev/prod api config
	var href = window.location.href;
	app.constant("Config", {
		// "API_BASE": "http://178.62.58.114/api/",

		// "API_BASE": "http://localhost:5556/api/"
		"API_BASE": "https://bellowildfood.com/api/"
		// "API_BASE": (href.indexOf("localhost") === -1)
		//  	? "https://bellowildfood.tk/api/"
		//  	: "http://localhost:5556/api/"
	});

})(angular);
