(function (angular) {

    "use strict";

    angular.module("app")
        .directive("manageOrder", function (OrderSvc, Config) {

            return {
                templateUrl: "views/directives/manage-order.html",
                restrict: "E",
                scope: {
                    order: "=",
                    showProductImage: "="
                },
                link: function ($scope, $element, $attributes) {

                    $scope.MEDIA_BASE = Config.API_BASE;

                    $scope.update = function () {

                        OrderSvc.update($scope.order)
                            .then(function (res) {

                                $scope.$emit("notify", {message: "Order updated."});
                            }, function (err) {
                                $scope.$emit("notify", {message: "Error updating order."});                        
                            });
                    };
                }
            };
        });

}) (angular);