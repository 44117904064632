/**
 * @overview Configure the app's status bar.
 */
(function (angular) {
    
        "use strict";
        
        var statusBarBgColor = "#000000";

        angular.module("app")
            .run(function ($cordovaStatusBar) {
                
               document.addEventListener('deviceready', function () {

                    $cordovaStatusBar.backgroundColorByHexString("#000");
                    $cordovaStatusBar.styleBlackOpaque();
                    
                }, false);
            });
    
}) (angular);