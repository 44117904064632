(function (angular) {
	
	"use strict";

	angular.module("app")
		.service("CalendarSvc", function ($http, Config, UploadSvc) {

            
            this.create = function (file, fields) {

                return new Promise(function (resolve, reject) {
                    UploadSvc.base64ToBlob(file, function (blob) {
                    
                        var data = new FormData();
        
                        for (var i in fields) {
                            if (fields.hasOwnProperty(i)) {
                                data.append(i, fields[i]);
                            }
                        }
        
                        data.append("file", blob);

                        $http.post(Config.API_BASE + "calendar", data, {
                            headers: { 'Content-Type': undefined},
                            transformRequest: angular.identity
                        })
                        .then(resolve, reject);
                    });
                });
            };

            this.delete = function (calendar_id) {
                return $http.delete(Config.API_BASE + "calendar/" + calendar_id);
            };
            this.get = function () {
                return $http.get(Config.API_BASE + "calendar");
            };
  
        });

}) (angular);