(function (angular) {
    "use strict";

    angular.module("app")
        .controller("ProductCtrl", function ($scope, $timeout, Product, ProductFactory, BasketSvc, Config) {
            
            $scope.product = new ProductFactory(Product.data);
            $scope.MEDIA_BASE = Config.API_BASE;

            $scope.quantityToAdd = 3;

            $scope.ui = {
                q: 2
            };

            /**
            * @function addToBasket Adds the current product to the user's current order
            */
            $scope.addToBasket = function (product) {
                var valid = BasketSvc.add(product, $scope.ui.q);

                if (valid !== false) {
                    $scope.$emit("notify", { message: "Product added to basket."});
                }
            };

            $scope.quantityChanged = function (product) {

                if (isNaN($scope.ui.q) == true) {
                    return;
                }

                //Check if they're trying to add more than is in stock..
                if ($scope.ui.q > product.stock) {
                    $scope.ui.q = product.stock;
                    $("quantity").val($scope.ui.q);
                }

                if (product.unit != "kg") {
 
                    $scope.ui.q = parseInt($scope.ui.q);

                    $("quantity").val($scope.ui.q);
                }

            }
        });

})(angular);