(function (angular) {
	"use strict";

	angular.module("app")
		.factory("ConfirmModal", function ($uibModal) {

			var default_controller = "ModalConfirmCtrl",
				default_template = "modal/confirm.html";

			var ModalInstance = function (options) {
				var options = options || {};
				this.templateUrl = options.templateUrl ? options.templateUrl : default_template;
				this.controller = options.templateUrl ? options.controller : default_controller;
			};

			var default_function = function () {};
			ModalInstance.prototype.open = function (options) {
				

				var instance = $uibModal.open({
					controller: this.controller,
					templateUrl: this.templateUrl,
					resolve: {
						Options: function () {
							return options.options;
						}
					}
				});

				instance.result.then(
					options.confirm || function () {}, 
					options.decline || function () {}
				);
			};

			return ModalInstance;
		});
})(angular);