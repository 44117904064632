(function (angular) {
	
	"use strict";

	angular.module("app")
		.controller("UserProfileCtrl", function ($scope, AuthSvc, User, UserSvc) {

			//User we're viewing on the page
			$scope.User = undefined;

			//Current logged in user
			$scope.current_user = AuthSvc.user;
			$scope.userGroups = AuthSvc.userGroups;


			var getUser = function () {

				if (User.data.data && User.data.data.length > 0) {
					$scope.User = User.data.data[0];
				}
				else {
					//We're creating a new user...
					$scope.User = {};
				}
			};

			$scope.submit = function () {

				($scope.User.hasOwnProperty("_id") == true) 
					? update() 
					: create();  				
			};

			var update = function () {

				UserSvc.update($scope.User._id, $scope.User)
					.then(function (res) {
						
						$scope.$emit("notify", {
							message: "Success editing user",
							type: "success"
						});

					}, function (err) {

						var err_res = err.data || {}; 

						$scope.$emit("notify", {
							message: "Error updating user: " + err_res.message,
							type: "error"
						})						
						console.log(err);
					});
			};

			var create = function () {
				UserSvc.create($scope.User)
					.then(function (res) {

						$scope.$emit("notify", {
							message: "Success creating user.",
							type: "success"
						});
						$scope.User = {};
					}, function (err) {
						console.log("Error creating user.");
						console.log(err);
						var err_res = err.data || {}; 

						$scope.$emit("notify", {
							message: "Error creating user: " + err_res.message,
							type: "error"
						});

					});
			};

			getUser();
		});

})(angular);