/**
 * Hide content the user does NOT have permission to view.
 *
 * Usage: 
 * <* permission="some-value">
 */
(function(angular){
    "use strict";

    angular.module("app")
        .directive("permission", function (AuthSvc) {
            return {
                restrict: "A",
                link: function (scope, element, attr) {

                    if (!AuthSvc.user) {
                        return;
                    }
                    
                    // Get the value given to the directive
                    var permission = attr["permission"];
                    
                    if (AuthSvc.user.user_group.permissions.indexOf(permission) === -1) {
                        element.remove();
                    }
                }
            };
        });

})(angular);