(function (angular) {

    "use strict";

    angular.module("app")
        .directive("inboxList", function ($state, $rootScope, PaginationFactory, InboxSvc) {

            var DEFAULT_ITEMS_PER_PAGE = 20;

            return {
                templateUrl: "views/directives/inbox-list.html",
                restrict: "E",
                scope: {
                    itemsPerPage: "=",
                    user: "="
                },
                link: function ($scope, $element, $attributes) {

                    $scope.inbox = [];
                    $scope.pagination = new PaginationFactory({
                        itemsPerPage: $scope.itemsPerPage || DEFAULT_ITEMS_PER_PAGE
                    });

                    $scope.getInbox = function () {

                        //Check if there are any more entries to load
						if ($scope.pagination.currentItems >= $scope.pagination.totalItems) {
							return;
						}
                        //Update the pagination object
						$scope.pagination.paginate();

                        InboxSvc.get($scope.pagination.start, $scope.pagination.itemsPerPage)
                            .then(function (res) {

                                $rootScope.$emit("inbox_update");

                                $scope.inbox = res.data.data;

                                //Update the pagination data 
								$scope.pagination.setTotalItems(res.data.count);

                            }, function (err) {
                                console.log("Error getting inbox");
                                console.log(err);
                            });
                    };

                    $scope.getInbox();
                }
            };
        });

}) (angular);