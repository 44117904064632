(function () {

    "use strict";

    angular.module("app")
        .controller("InboxCtrl", function ($scope, AuthSvc) {

            $scope.user = AuthSvc.getUser();
        });

})();