(function (angular) {
	
	"use strict";

	angular.module("app")
		.config(function ($urlRouterProvider, $stateProvider, Config) {

			$urlRouterProvider.otherwise("/login");

			$stateProvider.state("home", {
				url: "/,",
				params: {
					listings: false,
					product_key: null
				},
				templateUrl: "views/layouts/home.html",
				controller: "HomeCtrl"
			})
            .state("login", {
                url: "/login",
                templateUrl: "views/layouts/login.html",
                controller: "LoginCtrl"
			})
			.state("register", {
                url: "/register",
                templateUrl: "views/layouts/register.html",
                controller: "RegisterCtrl"
			})
			.state("public-shop", {
                url: "/public-shop",
                templateUrl: "views/layouts/public-shop.html",
                controller: "PublicShopCtrl"
			})
			.state("inbox", {
                url: "/inbox",
                templateUrl: "views/layouts/inbox.html",
                controller: "InboxCtrl"
            })
			.state("product", {
				url: "/product/:id",
				templateUrl: "views/layouts/product.html",
				controller: "ProductCtrl",
				resolve: {
					Product: function ($http, $stateParams) {
						return $http.get(Config.API_BASE + "product/" + $stateParams.id);
					}
				},
				data: {
					bodyClass: "view-product"
				}
			})
			.state("notifications", {
				url: "/notifications",
				templateUrl: "views/layouts/notifications.html",
				controller: "NotificationCtrl"
			})
			.state("create-product", {
				url: "/product/_create",
				templateUrl: "views/layouts/create-product.html",
				controller: "CreateProductCtrl"
			})
			.state("edit-product", {
				url: "/product/edit/:id",
				templateUrl: "views/layouts/create-product.html",
				controller: "EditProductCtrl",
				resolve: {
					Product: function ($http, $stateParams) {
						return $http.get(Config.API_BASE + "product/" + $stateParams.id);
					}
				}
			})
			.state("my-orders", {
				url: "/my-orders",
				templateUrl: "views/layouts/order/my-orders.html",
				controller: "MyOrdersCtrl"
			})
			.state("order-confirmed", {
				url: "/order-confirmed/:id",
				templateUrl: "views/layouts/order/confirmed-order.html",
				controller: "OrderConfirmedCtrl",
				resolve: {
					Order: function ($http, $stateParams) {
						return $http.get(Config.API_BASE + "order/" + $stateParams.id);
					}
				}
			})
			.state("order-thread", {
				url: "/order-thread/:id",
				templateUrl: "views/layouts/order/thread.html",
				controller: "OrderThreadCtrl",
				resolve: {
					Order: function ($http, $stateParams) {
						return $http.get(Config.API_BASE + "order/" + $stateParams.id);
					}
				}
			})
			.state("manage-orders", {
				url: "/manage-orders",
				templateUrl: "views/layouts/order/manage.html",
				controller: "ManageOrdersCtrl",
				// resolve: {
				// 	Orders: function ($http, $stateParams) {
				// 		return $http.get(Config.API_BASE + "order/" + $stateParams.id);
				// 	}
				// }
			})
			.state("manage-order", {
				url: "/manage-order/:id",
				templateUrl: "views/layouts/order/manage-single.html",
				controller: "ManageOrderCtrl",
				resolve: {
					Order: function ($http, $stateParams) {
						return $http.get(Config.API_BASE + "order/" + $stateParams.id);
					}
				}
			})
			.state("manage-users", {
				url: "/manage-users",
				templateUrl: "views/layouts/user/manage-list.html",
				controller: "ManageUsersCtrl"
			})
			.state("create-user", {
				url: "/create-user",
				templateUrl: "views/layouts/user/profile.html",
				controller: "UserProfileCtrl",
				resolve: {
					User: function () {
						return {data: {}};
					}
				}
			})
			.state("user-profile", {
				url: "/user-profile/:id",
				templateUrl: "views/layouts/user/profile.html",
				controller: "UserProfileCtrl",
				resolve: {
					User: function ($http, $stateParams) {
						return $http.get(Config.API_BASE + "user?_id="+ $stateParams.id);
					}
				}
			})
			.state("basket", {
                url: "/basket",
                templateUrl: "views/layouts/basket.html",
                controller: "BasketCtrl"
			})
			.state("upload-queue", {
                url: "/upload-queue",
                templateUrl: "views/layouts/upload-queue.html",
                controller: "UploadQueueCtrl"
			})
			.state("calendar-client", {
                url: "/calendar-client",
                templateUrl: "views/layouts/calendar-client.html",
                controller: "CalendarClientCtrl"
            })
			.state("calendar-admin", {
                url: "/calendar-admin",
                templateUrl: "views/layouts/calendar-admin.html",
                controller: "CalendarAdminCtrl"
            });
		});

})(angular);