(function (angular) {
    "use strict";

    angular.module("app")
        .controller("CalendarClientCtrl", function ($scope, $timeout, CalendarSvc, Config) {

            $scope.calendar = null;
            $scope.MEDIA_BASE = Config.API_BASE;
            $scope.calendarImg = "";

            CalendarSvc.get()
                .then(function (res) {
        
                    $scope.calendar = res.data;

                    $scope.calendarImg = $scope.MEDIA_BASE + 'public/stream/' + $scope.calendar.media + '?display=original&calendar=1';
                    $timeout(function () {
                        $("#calendar-img").magnificPopup({type:"image"});
                    });
                }, function (err) {
        
                    $scope.$emit("notify", {message: "Error getting calendar."});
                });

        });
        
})(angular);