(function (angular) {
	
	"use strict";
	angular.module("app")
		.service("UserSvc", function ($http, Config) {

			// /**
			// * @function getList Returns a list of all products
			// */
			this.getList = function (skip, limit, params) {
                
				return $http.get(Config.API_BASE + "user?skip=" + (skip || 0) + "&limit=" + limit);
			};

			/**
			* @function update Updates a given user account
			* @param {String} user_id ID of the user to update
			* @param {Object} data Props to update the user account with
			*/
			this.update = function (user_id, data) {

				return $http.put(Config.API_BASE + "user/" + user_id, data);
			};

			/**
			* @function create Creates a new user account
			* @param {Object} data New user's account properties
			*/
			this.create = function (data) {

				return $http.post(Config.API_BASE + "user", data);
			};

			/**
			* @function create Creates a new user account
			* @param {Object} data New user's account properties
			*/
			this.registerInterest = function (data) {

				return $http.post(Config.API_BASE + "public/registerinterest", data);
			};

        });

}) (angular);