(function (angular) {
    "use strict";


    var CATEGORIES = {

        "coastal": "Coastal",
        "inland": "Inland",
        "edible-flowers": "Edible Flowers",
        "mushrooms": "Mushrooms",
        "w-f-n": "Wild Fruit & Nuts",
        "seaweed": "Seaweed",
        "dried-goods": "Dried Goods",
        "decorative-products": "Decorative Products",
        "wild-essences": "Wild Essences",
        // "microgreens": "Microgreens"
    }

    var DEFAULT = "Unknown";

    angular.module("app")
        .filter("categoryToTitle", function () {

            //Work out the time difference
            return function (category) {


                return CATEGORIES[category] || DEFAULT;
            }
        });

})(angular);
