(function (angular) {
    "use strict";

    angular.module("app")
        .filter("timeago", function () {

            //Work out the time difference            
            return function (time) {

                // var now = moment();
                // var timeToMatch = moment(time);
                // var duration = moment.duration(now.diff(timeToMatch));
                // var timeAgo = duration.asMinutes();
                // var appendMessage = "minutes ago.";

                // if (timeAgo >= 60) {
                //     timeAgo = duration.asHours();
                //     appendMessage = "hours ago.";
                // }
                // if (appendMessage === "hours ago." && timeAgo >= 24) {
                //     timeAgo = duration.asDays();
                //     appendMessage = "days ago.";
                // }
                // if (appendMessage === "days ago." && timeAgo >= 31) {
                //     timeAgo = duration.asMonths();
                //     appendMessage = "months ago.";
                // }

                return moment(time).fromNow();
            }
        });

})(angular);