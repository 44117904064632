(function () {
    "use strict";

    angular.module("app")
        .filter("orderstatus", function () {

            //Value to display if the order status provided isn't accounted for.
            var ERROR_VALUE = "Unknown";

            //Human-readable values to display for the order status field
            var values = {
                "pending": "Pending",
                "in-progress": "In progress",
                "complete": "Complete",
                "cancelled": "Cancelled"
            };

            return function (input) {

                return values[input] || ERROR_VALUE;
            };  
        });

})();