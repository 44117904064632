(function (angular) {
    "use strict";

    angular.module("app")
        .controller("UploadQueueCtrl", function ($scope, UploadSvc) {
            
            $scope.ui = {
                upload_queue: UploadSvc.getQueue()
            };

            // console.log($scope.ui.upload_queue);

            $scope.deleteUpload = function (upload) {

            	UploadSvc.deleteNewUpload(upload);

            	$scope.ui.upload_queue = UploadSvc.getQueue();

            	$scope.$emit("notify", {
            		message: "Product deleted."
            	});
            };


        });

})(angular);