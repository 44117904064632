(function () {
    "use strict";

    var NOTIFICATION_TYPE_VALUES = {

        "order_message_post": {
            "title": "New message",
            "message": function () {

                return "This order has been updated, please review.";
            }
        },
        "order_post": {
            "title": "New order received",
            "message": function (n) {
                return "You have received a new order, please update it's status accordingly.";
            }
        },
        "order_put": {
            "title": "An order has been updated",
            "message": function (n) {
                return "The status of this order has changed, please review.";
            }
        },
        "order_delete": {
            "title": "Client has deleted their order.",
            "message": function (n) {
                return "This order has now been stricken from the system.";
            }
        },
        "product_post": {
            "title": "A new product has been added to the store.",
            "message": function (n) {
                if (!n.product) {
                    return "This product is now available for order.";
                }

                return n.product.title + " is now available for order";
            }
        }

    };

    angular.module("app")
        .factory("NotificationFactory", function (AuthSvc) {

            var Message = function (props) {
                var p = props || {};
                this._id = p._id || undefined;
                this.type = p.type || undefined;
                this.order = p.order || null;
                this.createdby = p.createdby || null;
                this.recipients = p.recipients || [];
                this.seen_by = p.seen_by || [];
                this.createdon = moment(p.createdon).toDate() || new Date();
                this.product = p.product || {};

                //Work out if the user has seen the notification or not
                var current_user = AuthSvc.getUser();
                
                this.seen = false;                
                if (current_user._id && this.seen_by.indexOf(current_user._id) !== -1) {
                    this.seen = true;
                }

                this.title = generateTitle(this.type);
                this.message = generateMessage(this);
            }

            var generateTitle = function (type) {
                var data = NOTIFICATION_TYPE_VALUES[type];

                if (!data) {
                    return false;
                }                
                
                return data.title;
            };
            var generateMessage = function (notification) {
                var data = NOTIFICATION_TYPE_VALUES[notification.type];

                if (!data) {
                    return false;
                }                
                
                return data.message(notification);
            };

            return Message;
        });
})();