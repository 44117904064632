(function (angular) {

    "use strict";

    angular.module("app")
        .directive("basket", function (BasketSvc, $state, ProductSvc) {

            return {
                templateUrl: "views/directives/basket-lg.html",
                restrict: "E",
                link: function ($scope, $element, $attributes) {
                    
                    $scope.basket = BasketSvc.get();

                    /**
                     * @function basketTotal Returns the total value of the items in the basket
                     */
                    $scope.basketTotal = function () {
                        return BasketSvc.getTotalValue();
                    };

                    /**
                     * @function updateBasket Updates the basket service's data with the changes from the view
                     */
                    $scope.updateBasket = function (basketItem) {

    
                        console.log(basketItem)

                        //Check if they're trying to add more than is in stock..
                        if (basketItem.quantity > basketItem.product.stock) {
                            basketItem.quantity = basketItem.product.stock;
                        }

                        if (isNaN(basketItem.quantity) === true) {
                            console.warn("quantity isNaN is true")
                            return;
                        }

                        if (basketItem.product.unit != "kg") {


                            basketItem.quantity = parseInt(basketItem.quantity);

                            $("quantity").val(basketItem.quantity);
                                
                        }

                        BasketSvc.update($scope.basket);
                    };

                    /**
                     * @function clearBasket Completely clears the basket
                     */
                    $scope.clearBasket = function () {

                        //Clear the BasketSvc's data
                        BasketSvc.reset();
                        
                        //Reset the local scope's data
                        $scope.basket = BasketSvc.get();

                        $scope.$emit("notify", { message: "Basket reset."});                        
                    };

                    /**
                     * @function removeItemFromBasket Removes an entire item (all quantities) from the basket.
                     */
                    $scope.removeItemFromBasket = function (item) {

                        //Remove the local copy of the item
                        _.remove($scope.basket, item);

                        //Apply changes to the BasketSvc
                        $scope.updateBasket();
                    }

                    $scope.confirmOrder = function () {

                        BasketSvc.purchaseOrder()
                            .then(function (res) {
                                console.log("Success placing order.");
                                console.log(res);                                

                                //Reset the basket.
                                BasketSvc.reset();

                                //Route to the my orders page.
                                $state.go("order-confirmed", {id: res.data._id});

                            }, function (err) {
                                console.log("Error placing order.");
                                console.log(err);
                                alert("An error occured placing your order.");
                            });
                    };
                }
            };
        });

}) (angular);