(function (angular) {
    
        "use strict";
    
        angular.module("app")
            .run(function (UploadSvc) {
                
            	//Just by requiring UploadSvc, we trigger the first initiationof the
            	//service, thus triggering it's upload check interval (singleton...)
            });
    
    }) (angular);