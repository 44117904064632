(function (angular) {
    "use strict";

    angular.module("app")
        .controller("OrderThreadCtrl", function ($scope, $window, MessageFactory, Order, OrderSvc, AuthSvc) {

            var order = Order.data;

            //Reference the current logged in user.
            $scope.currentUser = AuthSvc.user;

            //New message to tbe added to the thread
            $scope.newMessage = new MessageFactory();

            $scope.thread = [];

            $scope.$emit("inbox_update");
            
            $scope.back = function () {
                $window.history.back();

                //Do an extra 'back' to account for the bottom hash on the url
                if (window.location.href.indexOf("#bottom") !== -1) {
                    $window.history.back();                
                }
            };

            $scope.onReload = function () {
                console.log("Pull to refresh");
            };      

            $scope.send = function () {
                
                //Don't let the user send more than one message
                if ($scope.newMessage.content.length <= 0) {
                    return;
                }
                //Don't let the user upload more than one message at a time aka spam the thread
                else if ($scope.send.active === true) {
                    return;
                }
                
                $scope.send.active = true;

                OrderSvc.newThreadMessage($scope.newMessage, order._id)
                    .then(function (res) {

                        $scope.newMessage = new MessageFactory();
                        
                        $scope.getMessages();
                        
                        $scope.send.active = false;
                        
                    }, function (err) {

                        $scope.send.active = false;                
                        $scope.$emit("notify", {message: "Error sending message."});    
                    });
            };

            /**
             * @function getMessages Go fetch the message thread for this order.
             */
            $scope.getMessages = function () {

                if ($scope.getMessages.active === true) {
                    return;
                }

                $scope.getMessages.active = true;
                
                OrderSvc.getOrderThread(order._id)
                    .then(function (res) {
                        
                        $scope.thread = res.data;

                        $scope.getMessages.active = false;                        
                    }, function (err) {

                        $scope.getMessages.active = false;  
                        $scope.$emit("notify", {message: "Error getting message thread."});    
                    });

            };

            //Go fetch the thread to populate the view
            $scope.getMessages();

        });

})(angular);