(function (angular) {

	"use strict";
	angular.module("app")
		.service("ProductSvc", function ($http, Config, Upload) {

			var processProducts = function (res) {
				// This was taken from categoryToTitle filter
				var CATEGORIES = [
					"decorative-products",
					// "microgreens",
					"coastal",
					"inland",
					"edible-flowers",
					"w-f-n",
					"seaweed",
					"mushrooms",
					"dried-goods",
				];

				function compare(a, b) {
					var idA = CATEGORIES.indexOf(a._id);
					var idB = CATEGORIES.indexOf(b._id);

					var comparison = 0;
					if (idA > idB) {
						comparison = 1;
					} else if (idA < idB) {
						comparison = -1;
					}
					return comparison;
				}

				res.data.data.sort(compare);

				return res;
			};

			/**
			* @function getList Returns a list of all products
			*/
			this.getList = function (ids) {
				var params = {};
				if (ids) {
					params.ids = ids;
				}
				return new Promise(function (resolve, reject) {
					$http.get(Config.API_BASE + "product", {
						params: params
					}).then(function(res) {
						resolve(processProducts(res));
					});
				});
			};

			/**
			* @function getPublicList Returns the list of all public-facing products
			*/
			this.getPublicList = function () {

				return new Promise(function (resolve, reject) {
					$http.get(Config.API_BASE + "public/publicshop").then(function(res) {

						resolve(processProducts(res));
					});
				});
			};

			/**
			 * @function getWhatsHost Fetches the what's hot products from the API, retured in one flat array.
			 */
			this.getWhatsHot = function () {
				return $http.get(Config.API_BASE + "product/whatshot");
			};

			/**
			* @function create POSTS a new product to the API
			*/
			this.create = function (product) {

				var data = {};

				for (var i in product) {
					if (i !== "file" && i !== "image") {
						data[i] = product[i];
					}
				}

				return Upload.upload({
					url: Config.API_BASE + "product",
					arrayKey: "",
					data: {
						"product": data,
						"files": [product["file"]] || []
					},

				})
			};

			/**
			 * @function update Update a product
			 */
			this.update = function (product) {

				var data = {};

				for (var i in product) {
					if (i !== "file" && i !== "image") {
						data[i] = product[i];
					}
				}

				return Upload.upload({
					method: "PUT",
					url: Config.API_BASE + "product",
					arrayKey: "",
					data: {
						"product": data,
						"files": [product["file"]] || []
					},

				})
				// return $http.put(Config.API_BASE + "product", product);
			};

		});

}) (angular);
