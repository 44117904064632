(function (angular) {
    
        "use strict";
    
        angular.module("app")
            .directive("sideNav", function ($timeout, $window, BasketSvc, AuthSvc, $state) {
    
                return {
                    templateUrl: "views/directives/side-nav.html",
                    restrict: "E",
                    link: function ($scope, $element, $attributes) {

                        //Track if the menu is open or close.
                        $scope.menuOpen = false;

                        $(".sidebar-nav a[ui-sref], .sidebar-nav a[close-btn]").click(function(e) {
                            $scope.toggleMenu();
                        }); 

                        /**
                        * @function Toggles the side menu open/close
                        */
                        $scope.toggleMenu = function () {
                            $scope.menuOpen = !$scope.menuOpen;
                            $("#wrapper").toggleClass("toggled");
                        };

                        /**
                        * @function basketTotal Retrieves the total cost of the user's current order.
                        */
                        $scope.basketTotal = function  () {
                            return BasketSvc.getTotalValue();
                        };

                        /**
                        * @function goProducts Go to products screen.
                        */
                        $scope.goProducts = function (product_key) {

                            var options = {
                                listings: true
                            };

                            if (product_key) {
                                options.product_key = product_key;
                            }

                            $state.go("home", options);
                        };

                        $scope.collapseProducts = function () {

                            return false;
                        };

                        /**
                        * @function logout Logs the user out.
                        */
                        $scope.logout = function () {
                            AuthSvc.logout();
                        };
                        
                    }
                };
            });
    
    }) (angular);