(function (angular) {
	
	"use strict";
	angular.module("app")
		.service("InboxSvc", function ($http, Config) {

            
            /**
            * @function get Returns a list of messages for the user
            * @param {Number} skip Pagination...
            * @param {Number} limit Pagination...
			*/
			this.get = function (skip, limit) {
				return $http.get(Config.API_BASE + "order/inbox", {
                    params: {
                        skip: skip,
                        limit: limit
                    }
                });
            };
            

			/**
			* @function getUnseenCount Returns a count of all 'unseen' messages for the user
            */
			this.getUnseenCount = function () {
				return $http.get(Config.API_BASE + "order/inboxcount");
            };
		});

}) (angular);