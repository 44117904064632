(function (angular) {
    
        "use strict";
    
        angular.module("app")
            .run(function ($transitions, $trace, AuthSvc) {
                
                $trace.enable('TRANSITION');
                $transitions.onStart({ to: "*"}, function (trans) {
                    var toState = trans.to();
                    var authorised = AuthSvc.getToken();
                    var routeWhitelist = [
                        "login",
                        "public-shop",
                        "register"
                    ];
                    
                    if (routeWhitelist.indexOf(toState.name) === -1) {
                        //Redirect to login if the user is trying to 
                        //access a private page without being logged in.
                        if (!authorised) {
                            // User isn't authenticated. Redirect to a new Target State
                            return trans.router.stateService.target("login");
                        }
                    }
                    else {
                        //If the user tries to navigate to login, redirect to home if they're authorised already
                        if (authorised != null || authorised != undefined) {
                            return trans.router.stateService.target("home");                            
                        }
                    }                 
                });
            });
    
    }) (angular);