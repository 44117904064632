(function () {

	"use strict";

	angular.module("app")
    .factory('PaginationFactory', function() {

		var Pagination = function (data) {

			this.start = data ? data.start : 0;
			this.itemsPerPage = data ? data.itemsPerPage : 25; //Need to move items per page to system setting
			this.totalItems =  data ? data.totalItems : 9999;
			this.currentItems = data ? data.totalItems : 10;
			this.currentPage = data ? data.currentPage : 1;

			return this;
		};

		Pagination.prototype.setItemsPerPage = function (n) {
		   this.itemsPerPage = n;
		   return this;
	   };

		Pagination.prototype.reset = function () {
			this.start = 0;
			this.totalItems = 9999;
			this.currentItems = 10;
			this.currentPage = 1;

			return this;
		};

		Pagination.prototype.update = function (current_items, total_items, next_or_prev) {

			//If you're going forward a page or back a page
			if (next_or_prev) {
				this.start += this.itemsPerPage;
			}
			else {
				this.start -= this.itemsPerPage;
			}

			this.totalItems = total_items || 0;
			this.currentItems = current_items || 0;

			return this;
		};

		/*
			Paginate - based on angular ui pagination

			@param total_items {Number} Total population of the query
		*/
		Pagination.prototype.paginate = function () {

			//-1 from page number as angular pagination has a start index of 1
			this.start = this.itemsPerPage * (this.currentPage - 1);

			return this;
		};

		Pagination.prototype.setTotalItems = function (total_items) {
			this.totalItems = total_items || 0;
			//Work out how many pages for paging across
			this.numPages = Math.floor(this.totalItems / this.itemsPerPage);
			return this;
		};

	    return Pagination;
	});
})();
