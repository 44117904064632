(function (angular) {
    
        "use strict";
    
        angular.module("app")
            .run(function ($rootScope, ngNotify) {
                
                ngNotify.addTheme("bello", "bello");

				ngNotify.config({
				    theme: "bello",
				    // duration: 5000
				});

				$rootScope.$on("notify", function (_, data) {

					var props = Object.assign({
						position: "bottom"
					}, data);

					ngNotify.set(data.message , props);

				});
            });
    
    }) (angular);