(function (angular) {
    
    "use strict";

    angular.module("app")
        .run(function () {
            	
            /**
            * @function Array.move Move an element from one index to another
            */
            Array.prototype.move = function (old_index, new_index) {
			    if (new_index >= this.length) {
			        var k = new_index - this.length;
			        while ((k--) + 1) {
			            this.push(undefined);
			        }
			    }
			    this.splice(new_index, 0, this.splice(old_index, 1)[0]);
			    return this; // for testing purposes
			};
        });

}) (angular);