(function (angular) {
    "use strict";

    angular.module("app")
        .controller("ModalConfirmCtrl", function ($scope, $uibModalInstance, Options) {

            //Init
            (function () {

                //Assign any Options passed to $scope
                for (var i in Options) {
                    $scope[i] = Options[i];    
                }   
                
            })();  

            $scope.confirm = function () {
                $uibModalInstance.close();
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        });
})(angular);
