(function (angular) {
	
	"use strict";

	angular.module("app")
		.directive("keepScrollPos", function($transitions, $state, $window, $timeout, $location, $anchorScroll) {

		    // cache scroll position of each state's templateUrl
		    var scrollPosCache = {};

		    // compile function
		    return {
		    	link: function(scope, element, attrs) {

					$transitions.onStart({ }, function( trans ) {
						
						// store scroll position for the current view
			            if (trans.from().name) {
			                scrollPosCache[trans.from().templateUrl] = [ $window.pageXOffset, $window.pageYOffset ];
			            }

						trans.promise.finally(function () {


							// if hash is specified explicitly, it trumps previously stored scroll position
				            if ($location.hash()) {
				                $anchorScroll();

				            // else get previous scroll position; if none, scroll to the top of the page
				            } else {
								var prevScrollPos = scrollPosCache[trans.to().templateUrl] || [ 0, 0 ];
						
								$timeout(function() {
									$window.scrollTo(prevScrollPos[0], prevScrollPos[1]);
								}, 350);
				                
				            }
						});
					});
			    }
			}
		});

})(angular);