(function (angular) {

    "use strict";

    angular.module("app")
        .directive("inboxCount", function ($state, $rootScope, PaginationFactory, InboxSvc) {

            var DEFAULT_ITEMS_PER_PAGE = 20;

            return {
                template: "<span class='chalk text-center' ng-if='count > 0'>{{count}}</span>",
                restrict: "E",
                scope: {
                    itemsPerPage: "=",
                    user: "="
                },
                link: function ($scope, $element, $attributes) {

                    $scope.count = 0;

                    $rootScope.$on("inbox_update", function (obj, data) {
                        $scope.getCount();           
                    });
                    
                    $scope.getCount = _.throttle(function () {
                        
                        InboxSvc.getUnseenCount()
                            .then(function (res) {
          
                                $scope.count = res.data;

                            }, function (err) {
                                console.log("Error getting inbox");
                                console.log(err);
                            });
                    }, 100);

                    $scope.getCount();
                }
            };
        });

}) (angular);