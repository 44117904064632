(function (angular) {
	
	"use strict";
	angular.module("app")
		.service("AdminSvc", function ($http, $window, $httpParamSerializer, Config, AuthSvc) {

			/**
			* @function orderGetList Returns a list of orders 
			*/
			// this.orderGetList = function (skip, limit, status) {
				
			// 	var url = Config.API_BASE + "admin/orders?skip=" + skip + "&limit=" + limit;

			// 	if (status) {
			// 		url += "&status=" + status;
			// 	}
			// 	console.log("URL = " + url);
			// 	console.log("Status = " + status)
			// 	return $http.get(url);
			// };

			this.orderGetList = function (params) {
				var url = Config.API_BASE + "admin/orders";
				return $http.get(url, { params: params });
			};

			this.downloadOrderExport = function (params) {
				var url_params = params;
					url_params["jwt"] = AuthSvc.getToken();
				var url = Config.API_BASE + "order/export?" + $httpParamSerializer(url_params);
				$window.open(url);
			}
			
		});

}) (angular);